<template>
    <div>
        <h4 class="p-b-5">资源清单</h4>
        <Row class="table-title p-t-2">
            <i-col span="10">单品名称</i-col>
            <i-col span="7">发布费(4周)</i-col>
            <i-col span="7" class="text-center">操作</i-col>
        </Row>
        <div style="position: relative;">
            <Row v-for="(resource,index) in productSkuData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
                <i-col span="10">{{resource.productskuName}}</i-col>
                <i-col span="7">{{formatMoney(resource.usePrice*28)}}</i-col>
                <i-col span="7" class="text-center">
                    <a @click="OpenGeneratingConditionsModel(resource)">批量采购</a>
                </i-col>
            </Row>
        </div>

        <Modal v-model="showGenerateModel" width="400">
            <p slot="header" style="color:#f60;text-align:center">
                <Icon type="ios-information-circle"></Icon>
                <span>加入订单</span>
            </p>
            <Row class="p-b-10">
                <i-col span="24">
                    <span>自动生成采购数量 (当前档期可用余量为 <strong class="text-red">{{stock}}</strong>):</span>
                    <InputNumber v-model="purchaseQuantity" :max="100" :min="0"></InputNumber>
                </i-col>
            </Row>
            <div slot="footer">
                <Button type="error" size="large" :disabled="isSubmit" long @click="beginGenerate">开始生成</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { getStock } from '@/api/product/product'
import { getProductAndSkuList } from '@/api/product/productsku'
import { adddtProductSkus } from '@/api/order/orderitem'

import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      spinShow: false,
      query: {
        orderId: -1,
        assetIds: '', // 资产主键
        categoryId: this.categoryId, // 产品类别主键
        productId: null,
        schedules: [], // 订单采购档期
        pageNumber: 1, // 当前页
        keyword: '',
        pageSize: 10 // 页大小
      },
      productSkuData: [],
      defaultProduct: null,

      showGenerateModel: false,
      currentProductSku: {},
      stock: 0,
      purchaseQuantity: 0
    }
  },
  methods: {
    initPageData (searchBean) {
      this.query.orderId = searchBean.orderId
      this.query.assetIds = JSON.stringify(searchBean.assetIds) // 资产主键
      this.query.categoryId = searchBean.categoryId // 产品类别主键
      this.query.productId = searchBean.productId
      this.query.schedules = JSON.stringify(searchBean.schedules) // 订单采购档期
      this.query.pageNumber = 1
      this.query.keyword = ''

      this.defaultProduct = {
        startDate: searchBean.schedules[0].startDate,
        endDate: searchBean.schedules[0].endDate
      }
      // 加载所有可用的产品集合
      this.SeachResource()
      // 更新地图显示站点和线路
      const params = {
        data: [],
        checkedSchedules: searchBean.schedules,
        assetIds: searchBean.assetIds,
        condition: {}
      }
      this.$emit('on-updated-map', 'full', params)
    },
    SeachResource () {
      getProductAndSkuList(this.query).then(res => {
        this.productSkuData = res.list
      })
    },
    OpenGeneratingConditionsModel (productSku) {
      this.showGenerateModel = true
      this.currentProductSku = productSku
      this.getStock()
    },
    getStock () {
      const query = {
        productskuId: this.currentProductSku.productskuId,
        assetIds: this.query.assetIds,
        schedules: this.query.schedules,
        orderId: this.query.orderId
      }
      getStock(query).then(res => {
        this.stock = res.quantity
      })
    },
    beginGenerate () {
      if (this.stock && !this.spinShow) {
        this.spinShow = true
        const GenerationCondition = {
          orderId: this.query.orderId, // 采购ID
          assetIds: this.query.assetIds, // 线路集合
          productskus: JSON.stringify([{ productskuId: this.currentProductSku.productskuId, quantity: this.purchaseQuantity }]),
          schedules: this.query.schedules
        }

        adddtProductSkus(GenerationCondition).then(res => {
          if (res && !res.errcode) {
            this.$Notice.success({
              title: '资源添加成功!',
              desc: '请在页面右侧【采购清单】中查看购买详情！'
            })
            this.SeachResource()
            // 默认选中编辑状态
            this.defaultProduct.productId = this.currentProductSku.productId
            this.$emit('on-update-order', this.defaultProduct)
          }

          this.showGenerateModel = false
          this.spinShow = false
        }).catch(() => {
          this.spinShow = false
        })
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    reload () {
      this.SeachResource()
    }
  },
  computed: {
    isSubmit () {
      return this.purchaseQuantity === null || this.purchaseQuantity === 0 || this.purchaseQuantity > this.stock
    }
  }
}
</script>
